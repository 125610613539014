<template>
	<div  class="item">
		<div class="chat self">
			<div class="img-box"><img v-if="avatar" :src="avatar"></div>
			<div>
				<msg-box :data="item"/>
			</div>
		</div>
	</div>

</template>

<script>
import msgBox from '../../components/msg-box'
import { mapGetters } from 'vuex'
export default {
	name: 'chat',
	components: { msgBox },
	props: {
		item: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		...mapGetters(['robotInfo']),
		avatar() {
			return this.robotInfo.headImgUrl
		}
	},
}
</script>

<style lang="scss" scoped>
.item{
	-webkit-animation: fade-in .3s ease both;
	.tips{
		margin: .2rem 0;
		text-align: center;
		font-size: .12rem;
		color: #a5a5a5;
	}
	.red-bag-tips{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: .1rem 0;
		text-align: center;
		font-size: .12rem;
		color: #a5a5a5;
		.icon{
			margin-right: .1rem;
			width: .15rem;
			height: .18rem;
			background: url("assets/icon-02.png") center no-repeat;
			background-size: 100% 100%;
		}
		.c1{
			color: #fb973d;
		}
	}
	.chat{
		margin: .15rem 0;
		display: flex;
		.name{
			margin: -.05rem 0 .03rem;
			padding: 0 .05rem;
			font-size: .12rem;
			color: #a5a5a5;
		}
		.img-box{
			margin-right: .1rem;
			width: .4rem;
			height: .4rem;
			border-radius: .04rem;
			overflow: hidden;
			background: #ccc;
			img{
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		::v-deep {
			.text{
				display: inline-block;
				min-width: .35rem;
				max-width: 2.7rem;
				position: relative;
				padding: .08rem .1rem;
				background: #fff;
				border-radius: .04rem;
				font-size: .15rem;
				line-height: .24rem;
				box-sizing: border-box;
				word-break: break-all;
				white-space: pre-line;
				&:before{
					content: "";
					position: absolute;
					top: .15rem;
					left: -.035rem;
					width: .07rem;
					height: .07rem;
					background: #fff;
					transform: rotate(45deg);
				}
				img{
					display: inline-block;
					height: .15rem;
					vertical-align: middle;
				}
			}
			.img{
				width: auto;
				height: auto;
				max-width: px(160);
				max-height: px(160);
			}
			.file{
				background: #fff;
			}
			.card{
				background: #fff;
			}
			.link{
				background: #fff;
			}
			.mini-program{
				background: #fff;
			}
		}
		&.self{
			justify-content: flex-end;
			&>div:first-child{
				order: 1;
			}
			.img-box{
				margin-right: 0;
				margin-left: .1rem;
			}
			.name{
				display: none;
			}
			::v-deep .text{
				background: #3784F8;
				color: #fff;
				&:before{
					left: auto;
					right: -5px;
					right: -.05rem;
					width: 0;
					height: 0;
					border-top: solid .05rem transparent;
					border-left: solid .05rem #3784F8;
					border-bottom: solid .05rem transparent;
					transform:none;
					background: none;
				}
			}
		}
	}
	&:last-child{
		.chat{
			margin-bottom: 0;
		}
	}
}
</style>
